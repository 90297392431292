import { ColorPalette } from '@graphics-flow/types';


export class ColorPaletteHelper {

  static generateColorPaletteForSave(colorPalette: ColorPalette, updatedColorPalette: ColorPalette): ColorPalette {

    return Object.assign(colorPalette, updatedColorPalette);
  }
}
