import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { UserHttpService } from '@graphics-flow/api';
import { PendoData, PendoMetaData } from '@graphics-flow/types';

@Injectable({
  providedIn: 'root'
})
export class PendoService {
  private metaData: PendoMetaData;
  private pendo: any;

  constructor(
    private readonly userHttpService: UserHttpService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  initiatePendo(): void {
    if (this.pendo) {
      return this.pendo.initialize(this.metaData);
    }
    this.getPendoScript().pipe(
      tap((data: PendoMetaData) => {
        this.metaData = data;
        this.pendo.initialize(data);
      })
    ).subscribe();
  }

  getPendoScript(): Observable<PendoMetaData> {
    return this.userHttpService.getPendoScript().pipe(
      map(({ script1, script2 }: PendoData) => {
        if (!this.pendo) {
          this.loadScript(script1);
        }
        return script2;
      })
    );
  }

  loadScript(content: string): void {
    const element = this.document.createElement('script');
    element.id = 'script-pendo';
    element.type = 'text/javascript';
    element.text = content;
    element.async = true;
    this.document.head.appendChild(element);
    this.pendo = (window as any).pendo;
  }

  reset(): void {
    delete (window as any).pendo;
    this.pendo = null;
    this.metaData = null;
  }
}
