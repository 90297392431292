import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { CustomColor } from '@graphics-flow/types';

@Component({
  selector: 'add-art-color',
  templateUrl: './add-art-color.component.html',
  styleUrls: ['./add-art-color.component.scss']
})
export class AddArtColorComponent {

  @Input() color;
  @Input() disabled;
  @Input() isColorPalette = false;
  @Input() showCMYKPicker = false;
  @Output() colorChange: EventEmitter<string> = new EventEmitter();
  @Output() allColorFormat: EventEmitter<Partial<CustomColor>> = new EventEmitter();
  @Output() showCMYKPickerFormat: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public translations: Translations
  ) { }

  colorPickerClosed(): void {
    this.colorChange.emit(this.color);
  }

  toggleColorPickerType(): void {
    this.showCMYKPickerFormat.next(!this.showCMYKPicker)
  }

}
