<div [id]="color.name"
  class="swatch"
  [style.backgroundColor]="(isSystemColorPalette ? '#': '') + color?.hexValue"
  (click)="show = !show"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"></div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="show"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -4
    }
  ]"
>
  <gf-color-info-tooltip [color]="color"></gf-color-info-tooltip>
</ng-template>
