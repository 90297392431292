import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { DndModule } from '@ng-dnd/core';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxFilesizeModule } from 'ngx-filesize';
// App Modules
import { GfUtilModule } from '@graphics-flow/util';
import { SharedUiModule } from 'shared/ui';
import { SharedUtilsModule } from 'shared/util';
// Dialog Components
import { AddArtToApprovalDialogComponent } from './components/add-art-to-approval-dialog/add-art-to-approval-dialog.component';
import { AddCollaboratorToApprovalDialogComponent } from './components/add-collaborator-to-approval-dialog/add-collaborator-to-approval-dialog.component';
import { AddTeammateDialogComponent } from './components/add-teammate-dialog/add-teammate-dialog.component';
import { ApprovalItemConfirmationDialogComponent } from './components/approval-item-confirmation-dialog/approval-item-confirmation-dialog.component';
import { CategoryFilterDialogComponent } from './components/category-filter-dialog/category-filter-dialog.component';
import { CollectionFilterDialogComponent } from './components/collection-filter-dialog/collection-filter-dialog.component';
import { CreateArtApprovalDialogComponent } from './components/create-art-approval-dialog/create-art-approval-dialog.component';
import { DeleteApprovalDialogComponent } from './components/delete-approval-dialog/delete-approval-dialog.component';
import { EditTagsDialogComponent } from './components/edit-tags-dialog/edit-tags-dialog.component';
import { FinalApprovalDialogComponent } from './components/final-approval-dialog/final-approval-dialog.component';
import { HelpAndResourcesDialogComponent } from './components/help-and-resources-dialog/help-and-resources-dialog.component';
import { MergeTagsDialogComponent } from './components/merge-tags-dialog/merge-tags-dialog.component';
import { OrientationNotSupportedDialogComponent } from './components/orientation-not-supported-dialog/orientation-not-supported-dialog.component';
import { StyleFilterDialogComponent } from './components/style-filter-dialog/style-filter-dialog.component';
import { TagManagerDialogComponent } from './components/tag-manager-dialog/tag-manager-dialog.component';
// Components
import { AddArtColorComponent } from './components/add-art-color/add-art-color.component';
import { AddToApprovalMsgComponent } from './components/add-to-approval-msg/add-to-approval-msg.component';
import { AlertPanelComponent } from './components/alert-panel/alert-panel.component';
import { ApprovalCollaboratorItemComponent } from './components/approval-collaborator-item/approval-collaborator-item.component';
import { ApprovalCommentComponent } from './components/approval-comment/approval-comment.component';
import { ApprovalCommentReplyComponent } from './components/approval-comment-reply/approval-comment-reply.component';
import { ApprovalCommentsComponent } from './components/approval-comments/approval-comments.component';
import { ApprovalDropComponent } from './components/approval-drop/approval-drop.component';
import { ApprovalItemCtaComponent } from './components/approval-item-cta/approval-item-cta.component';
import { ArtDescriptionComponent } from './components/art-description/art-description.component';
import { ArtImageComponent } from './components/art-image/art-image.component';
import { ArtListComponent } from './components/art-list/art-list.component';
import { CategoryFilterComponent } from './components/category-filter/category-filter.component';
import { CollaboratorAvatarComponent } from './components/collaborator-avatar/collaborator-avatar.component';
import { CommentInputComponent } from './components/comment-input/comment-input.component';
import { DownloadAllFontsComponent } from './components/download-all-fonts/download-all-fonts.component';
import { StockArtTypeFilterComponent } from './components/stock-art-type-filter/stock-art-type-filter.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { FabulousMenuComponent } from './components/fabulous-menu/fabulous-menu.component';
import { FabulousTriggerDirective } from './components/fabulous-menu/fabulous-trigger.directive';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FolderBreadcrumbsComponent } from './components/folder-breadcrumbs/folder-breadcrumbs.component';
import { FolderDropComponent } from './components/folder-drop/folder-drop.component';
import { FolderMoveDialogComponent } from './components/folder-move-dialog/folder-move-dialog.component';
import { FolderMoveListItemComponent } from './components/folder-move-dialog/folder-move-list-item/folder-move-list-item.component';
import { MobileFolderMoveListItemComponent } from './components/folder-move-dialog/mobile-folder-move-list-item/mobile-folder-move-list-item.component';
import { FontCardComponent } from './components/font-card/font-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { GfHeaderComponent } from './components/gf-header/gf-header.component';
import { GfLogoComponent } from './components/gf-logo/gf-logo.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';
import { LinkNotificationMessageComponent } from './components/link-notification-message/link-notification-message.component';
import { ManageArtTagComponent } from './components/manage-art-tag/manage-art-tag.component';
import { ManageDecorationMethodsComponent } from './components/manage-decoration-methods-dialog/manage-decoration-methods-modal.component';
import { MyArtFileUploadComponent } from './components/my-art-file-upload/my-art-file-upload.component';
import { NewApprovalShelfItemComponent } from './components/new-approval-shelf/new-approval-shelf-item/new-approval-shelf-item.component';
import { NotificationsIndicatorComponent } from './components/notifications-indicator/notifications-indicator.component';
import { PlansDetailsComponent } from './components/plans-details/plans-details.component';
import { RenameArtDialogComponent } from './components/rename-art-dialog/rename-art-dialog.component';
import { SelectArtDialogComponent } from './components/select-art-dialog/select-art-dialog.component';
import { ShadyScrollerComponent } from './components/shady-scroller/shady-scroller.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { StockArtChipsComponent } from './components/stock-art-chips/stock-art-chips.component';
import { StockArtDownloadedComponent } from './components/stock-art-downloaded/stock-art-downloaded.component';
import { StockArtFiltersComponent } from './components/stock-art-filters/stock-art-filters.component';
import { StockArtImageComponent } from './components/stock-art-image/stock-art-image.component';
import { StockArtItemComponent } from './components/stock-art-item/stock-art-item.component';
import { StockArtListComponent } from './components/stock-art-list/stock-art-list.component';
import { StockArtListFilterComponent } from './components/stock-art-list-filter/stock-art-list-filter.component';
import { StockArtListResultsHeaderComponent } from './components/stock-art-list-results-header/stock-art-list-results-header.component';
import { StockArtMenuActionsComponent } from './components/stock-art-menu-actions/stock-art-menu-actions.component';
import { TeammateAvatarComponent } from './components/teammate-avatar/teammate-avatar.component';
import { UsageWarningStatusComponent } from './components/usage-warning-status/usage-warning-status.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { UserDropdownComponent } from './components/user-dropdown/user-dropdown.component';
// Pipes
import { DetailViewContainerComponent } from './components/detail-view-container/detail-view-container.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { FolderInputComponent } from './components/folder-input/folder-input.component';
import { FooterViewComponent } from './components/footer-view/footer-view.component';
import { SaveInFolderSelectionComponent } from './components/save-in-folder-selection/save-in-folder-selection.component';
import { SidenavFooterComponent } from './components/sidenav-footer-action/sidenav-footer-action.component';
import { AddDomainPipe } from './pipes/add-domain.pipe';
import { ApprovalGetDisplayNamePipe } from './pipes/approval-get-display-name.pipe';
import { ArtExtensionPipe } from './pipes/art-extension.pipe';
import { ArtPipe } from './pipes/art.pipe';
import { BorderColorPipe } from './pipes/border-color.pipe';
import { CanDeleteCommentPipe } from './pipes/can-delete-comment.pipe';
import { CollaboratorPipe } from './pipes/collaborator.pipe';
import { DateWithTimeZone } from './pipes/date-with-timezone.pipe';
import { FileExtensionPipe } from './pipes/file-extension.pipe';
import { FileNamePipe } from './pipes/file-name.pipe';
import { FolderPipe } from './pipes/folder.pipe';
import { FontPreviewUrlPipe } from './pipes/font-preview-url.pipe';
import { FormatDateByDifferencePipe } from './pipes/format-date-by-difference.pipe';
import { FromNowDatePipe } from './pipes/from-now-date.pipe';
import { GetBase64PreviewsPipe } from './pipes/get-base64-preview.pipe';
import { GetCollaboratorPermissionOrDescriptionPipe } from './pipes/get-collaborator-permission-or-description.pipe';
import { GetCommentRepliesPipe } from './pipes/get-comment-replies.pipe';
import { GetCommentsPipe } from './pipes/get-comments.pipe';
import { GetTagByCanonicalNamePipe } from './pipes/get-tag-by-canonical-name.pipe';
import { GetTeamsInfoPipe } from './pipes/get-teams-info.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { IconBackgroundPipe } from './pipes/icon-background.pipe';
import { ImagePreviewPipe } from './pipes/image-preview.pipe';
import { IsApprovalApprovedPipe } from './pipes/is-approval-approved.pipe';
import { IsApprovalArchivedPipe } from './pipes/is-approval-archived.pipe';
import { IsCategoryIndeterminatePipe } from './pipes/is-category-indeterminate.pipe';
import { IsCSASupportedArtPipe } from './pipes/is-csa-supported-art.pipe';
import { IsDescendantAvailablePipe } from './pipes/is-descendant-available.pipe';
import { IsDowngradeAllowedPipe } from './pipes/is-downgrade-allowed.pipe';
import { IsInProgressPipe } from './pipes/is-in-progress.pipe';
import { IsMyArtSelectedPipe } from './pipes/is-my-art-selected.pipe';
import { IsNoPreviewImagePipe } from './pipes/is-no-preview-image.pipe';
import { IsOwnerOrAdminPipe } from './pipes/is-owner-or-admin.pipe';
import { IsOwnerPipe } from './pipes/is-owner.pipe';
import { IsPlanUpdatedPipe } from './pipes/is-plan-updated.pipe';
import { IsStockArtPlanDowngradedPipe } from './pipes/is-stock-art-plan-downgraded.pipe';
import { MyArtImagePreviewPipe } from './pipes/my-art-image-preview.pipe';
import { OrganizationImageUrlPipe } from './pipes/organization-image-url.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { SelectedBundlePlanPipe } from './pipes/selected-bundle-plan.pipe';
import { SelectedPlanUsageWarningInfoPipe } from './pipes/selected-plan-usage-info.pipe';
import { ShortNamePipe } from './pipes/short-name.pipe';
import { StockArtArtPipe } from './pipes/stock-art-art.pipe';
import { StockArtTypePipe } from './pipes/stock-art-type.pipe';
import { StockArtPipe } from './pipes/stock-art.pipe';
import { UniquePipe } from './pipes/unique.pipe';
import { UserDisplayNamePipe } from './pipes/user-display-name.pipe';
import { UserPipe } from './pipes/user.pipe';
import { ColorSwatchComponent } from './components/color-swatch/color-swatch.component';
import { ColorInfoTooltipComponent } from './components/color-info-tooltip/color-info-tooltip.component';
import { ColorFomatterPipe } from './pipes/color-formatter.pipe';

const COMPONENTS = [
  AddArtColorComponent,
  AddArtToApprovalDialogComponent,
  AddCollaboratorToApprovalDialogComponent,
  AddTeammateDialogComponent,
  AddToApprovalMsgComponent,
  AlertPanelComponent,
  ApprovalCollaboratorItemComponent,
  ApprovalCommentComponent,
  ApprovalCommentReplyComponent,
  ApprovalCommentsComponent,
  ApprovalDropComponent,
  ApprovalItemConfirmationDialogComponent,
  ApprovalItemCtaComponent,
  ArtDescriptionComponent,
  ArtImageComponent,
  ArtListComponent,
  CategoryFilterComponent,
  CategoryFilterDialogComponent,
  CollaboratorAvatarComponent,
  CollectionFilterDialogComponent,
  CommentInputComponent,
  CreateArtApprovalDialogComponent,
  DeleteApprovalDialogComponent,
  DetailViewContainerComponent,
  DownloadAllFontsComponent,
  EditTagsDialogComponent,
  EmptyStateComponent,
  ErrorPageComponent,
  FabulousMenuComponent,
  FabulousTriggerDirective,
  FileInputComponent,
  FinalApprovalDialogComponent,
  FolderBreadcrumbsComponent,
  FolderDropComponent,
  FolderInputComponent,
  FolderMoveDialogComponent,
  FolderMoveListItemComponent,
  FolderMoveListItemComponent,
  FontCardComponent,
  FooterComponent,
  FooterViewComponent,
  GfHeaderComponent,
  GfLogoComponent,
  HelpAndResourcesDialogComponent,
  HelpCenterComponent,
  LinkNotificationMessageComponent,
  ManageArtTagComponent,
  ManageDecorationMethodsComponent,
  MergeTagsDialogComponent,
  MobileFolderMoveListItemComponent,
  MyArtFileUploadComponent,
  NewApprovalShelfItemComponent,
  NotificationsIndicatorComponent,
  OrientationNotSupportedDialogComponent,
  PlansDetailsComponent,
  RenameArtDialogComponent,
  SaveInFolderSelectionComponent,
  SelectArtDialogComponent,
  ShadyScrollerComponent,
  SidebarComponent,
  SidenavFooterComponent,
  StockArtChipsComponent,
  StockArtDownloadedComponent,
  StockArtFiltersComponent,
  StockArtTypeFilterComponent,
  StockArtImageComponent,
  StockArtItemComponent,
  StockArtListComponent,
  StockArtListFilterComponent,
  StockArtListResultsHeaderComponent,
  StockArtMenuActionsComponent,
  StyleFilterDialogComponent,
  TagManagerDialogComponent,
  TeammateAvatarComponent,
  UsageWarningStatusComponent,
  UserAvatarComponent,
  UserDropdownComponent,
  ColorSwatchComponent,
  ColorInfoTooltipComponent
];

const PIPES = [
  AddDomainPipe,
  ApprovalGetDisplayNamePipe,
  ArtExtensionPipe,
  ArtPipe,
  BorderColorPipe,
  CanDeleteCommentPipe,
  CollaboratorPipe,
  ColorFomatterPipe,
  DateWithTimeZone,
  FileExtensionPipe,
  FileNamePipe,
  FolderPipe,
  FontPreviewUrlPipe,
  FormatDateByDifferencePipe,
  FromNowDatePipe,
  GetBase64PreviewsPipe,
  GetCollaboratorPermissionOrDescriptionPipe,
  GetCommentRepliesPipe,
  GetCommentsPipe,
  GetTagByCanonicalNamePipe,
  GetTeamsInfoPipe,
  HighlightPipe,
  IconBackgroundPipe,
  ImagePreviewPipe,
  IsApprovalApprovedPipe,
  IsApprovalArchivedPipe,
  IsCategoryIndeterminatePipe,
  IsCSASupportedArtPipe,
  IsDescendantAvailablePipe,
  IsDowngradeAllowedPipe,
  IsInProgressPipe,
  IsMyArtSelectedPipe,
  IsNoPreviewImagePipe,
  IsOwnerOrAdminPipe,
  IsOwnerPipe,
  IsPlanUpdatedPipe,
  IsStockArtPlanDowngradedPipe,
  MyArtImagePreviewPipe,
  OrganizationImageUrlPipe,
  PercentagePipe,
  SelectedBundlePlanPipe,
  SelectedPlanUsageWarningInfoPipe,
  ShortNamePipe,
  StockArtArtPipe,
  StockArtPipe,
  StockArtTypePipe,
  UniquePipe,
  UserDisplayNamePipe,
  UserPipe
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GfUtilModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    NgxFilesizeModule,
    ReactiveFormsModule,
    RouterModule,
    SharedUiModule,
    DndModule,
    TranslateModule,
    PortalModule,
    OverlayModule,
    MatSidenavModule,
    RecaptchaModule,
    TextFieldModule,
    ScrollingModule,
    MatTabsModule,
    MatStepperModule,
    VirtualScrollerModule,
    SharedUtilsModule
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES
  ],
  providers: [
    ImagePreviewPipe
  ],
  exports: [
    ...COMPONENTS,
    ...PIPES
  ]
})
export class GfUiModule {}
