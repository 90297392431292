import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CustomColor } from '@graphics-flow/types';
import { ColorHelpers } from 'shared/util';
import { ColorGroup, Hsla, Hsva, Rgba, SliderDimension, SliderPosition } from './color-picker.classes';
import { ColorPickerService } from './color-picker.service';
@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit, AfterViewInit {
  @Input() color: string;
  @Input() colorGroups: ColorGroup[];
  @Input() showAlpha = false;
  @Input() allowClear = true;
  @Input() allowWheel = true;
  @Input() isColorPalette = false;
  @Input() showCMYKPicker = false;
  @Output() colorChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() allColorFormat: EventEmitter<Partial<CustomColor>> = new EventEmitter<Partial<CustomColor>>();

  public hsva: Hsva;
  public rgbaText: Rgba;
  public hslaText: Hsla;
  public hexText: string;
  public cmykText: string;
  public alphaSliderColor: string;
  public hueSliderColor: string;
  public slider: SliderPosition;
  public sliderDimMax: SliderDimension;
  public format: number;
  public cpOutputFormat: string;

  @ViewChild('saturationAndBrightnessSlider', { static: true }) saturationAndBrightnessSlider: any;
  @ViewChild('hueSlider', { static: true }) hueSlider: any;
  @ViewChild('alphaSlider') alphaSlider: any;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.update();
  }

  constructor(
    private service: ColorPickerService) {
  }

  ngOnInit() {
    const hsva = this.service.stringToHsva(this.color);
    if (hsva) {
      this.hsva = hsva;
    }
    this.sliderDimMax = new SliderDimension(0, 0, 0, 0);
    this.slider = new SliderPosition(0, 0, 0, 0);
    // show RGB input when no color is there initially
    if (this.cpOutputFormat === 'rgba' || !this.color) {
      this.format = 1;
    } else if (this.cpOutputFormat === 'hsla') {
      this.format = 2;
    } else {
      this.format = 0;
    }
    if (!this.isColorPalette) {
      this.service.currentColor$.subscribe((color: string) => {
        this.hsva = this.service.stringToHsva(color);
        this.update(false);
      });
    }
  }

  ngAfterViewInit() {
    this.updateSliderDimension();

    setTimeout(() => {
      this.update(false);
    }, 0);
  }

  updateSliderDimension() {
    const saturationAndBrightnessWidth = this.saturationAndBrightnessSlider.nativeElement.offsetWidth;
    const alphaWidth = this.showAlpha ? this.alphaSlider.nativeElement.offsetWidth : 1;
    const hueWidth = this.hueSlider.nativeElement.offsetWidth;

    this.sliderDimMax = new SliderDimension(hueWidth, saturationAndBrightnessWidth, 130, alphaWidth);
  }

  setSaturation(val: { v: number, rg: number }) {
    const hsla = this.service.hsva2hsla(this.hsva);
    hsla.s = val.v / val.rg;
    this.hsva = this.service.hsla2hsva(hsla);
    this.update();
  }

  setLightness(val: { v: number, rg: number }) {
    const hsla = this.service.hsva2hsla(this.hsva);
    hsla.l = val.v / val.rg;
    this.hsva = this.service.hsla2hsva(hsla);
    this.update();
  }

  setHue(val: { v: number, rg: number }) {
    this.hsva.h = val.v / val.rg;
    this.update();
  }

  setAlpha(val: { v: number, rg: number }) {
    this.hsva.a = val.v / val.rg;
    this.update();
  }

  setR(val: { v: number, rg: number }) {
    const rgba = this.service.hsvaToRgba(this.hsva);
    rgba.r = val.v / val.rg;
    this.hsva = this.service.rgbaToHsva(rgba);
    this.update();
  }

  setG(val: { v: number, rg: number }) {
    const rgba = this.service.hsvaToRgba(this.hsva);
    rgba.g = val.v / val.rg;
    this.hsva = this.service.rgbaToHsva(rgba);
    this.update();
  }

  setB(val: { v: number, rg: number }) {
    const rgba = this.service.hsvaToRgba(this.hsva);
    rgba.b = val.v / val.rg;
    this.hsva = this.service.rgbaToHsva(rgba);
    this.update();
  }

  setSaturationAndBrightness(val: { s: number, v: number, rgX: number, rgY: number }) {
    this.hsva.a = 1;
    this.hsva.s = val.s / val.rgX;
    this.hsva.v = val.v / val.rgY;
    this.update();
  }

  setColorFromString(value: string) {
    const hsva = this.service.stringToHsva(value);
    if (hsva !== null) {
      this.hsva = hsva;
    }
    this.update();
  }

  formatPolicy(): number {
    this.format = (this.format + 1) % 3;
    if (this.format === 0 && this.hsva && this.hsva.a < 1) {
      this.format++;
    }
    return this.format;
  }

  update(emitEvent: boolean = true) {
    this.updateSliderDimension();

    if (this.hsva) {
      const hsla = this.service.hsva2hsla(this.hsva);
      const rgba = this.service.denormalizeRGBA(this.service.hsvaToRgba(this.hsva));
      const hueRgba = this.service.denormalizeRGBA(this.service.hsvaToRgba(new Hsva(this.hsva.h, 1, 1, 1)));
      const cmyk = this.service.rgb2cmyk(rgba.r, rgba.g, rgba.b);

      this.hslaText = new Hsla(Math.round((hsla.h) * 360), Math.round(hsla.s * 100), Math.round(hsla.l * 100),
        Math.round(hsla.a * 100) / 100);
      this.rgbaText = new Rgba(rgba.r, rgba.g, rgba.b, Math.round(rgba.a * 100) / 100);
      this.hexText = this.service.hexText(rgba);
      this.cmykText = this.service.cmykText(this.service.rgb2cmyk(rgba.r, rgba.g, rgba.b));

      this.alphaSliderColor = 'rgb(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ')';
      this.hueSliderColor = 'rgb(' + hueRgba.r + ',' + hueRgba.g + ',' + hueRgba.b + ')';

      if (this.format === 0 && this.hsva.a < 1) {
        this.format++;
      }

      if (emitEvent) {
        if (this.hsva.a === 0) {
          this.colorChange.emit();
        } else {
          this.colorChange.emit(this.service.outputFormat(this.hsva, this.cpOutputFormat));
          this.allColorFormat.emit({
            hexValue: this.hexText,
            rgb: { red: rgba.r, green: rgba.g, blue: rgba.b },
            cmyk
          });
        }
      }

      this.slider = new SliderPosition((this.hsva.h) * this.sliderDimMax.h - 8, this.hsva.s * this.sliderDimMax.s - 8,
        (1 - this.hsva.v) * this.sliderDimMax.v - 8, this.hsva.a * this.sliderDimMax.a - 8);
    } else {
      // If no color has been set yet, use these values until the user selects something
      this.hsva = new Hsva(1, 1, 1, 0);
      this.alphaSliderColor = 'rgb(255, 0, 0)';
      this.hueSliderColor = 'rgb(255, 0, 0)';
      this.hexText = '';
      this.slider = new SliderPosition(this.sliderDimMax.h - 8, this.sliderDimMax.s - 8, -8, this.sliderDimMax.a - 8);
    }
  }

  getBorderColor(color) {
    return ColorHelpers.getContrastColorValue(color) <= 0.12 ? '#E0E0E0' : color;
  }

  clearColor() {
    if (this.allowClear) {
      this.hsva.a = 0;
      this.color = '';
      this.update();
      this.colorChange.emit();
    }
  }
}
