import { Component, HostListener, Input } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { CustomColor, SystemColor } from '@graphics-flow/types';

@Component({
  selector: 'gf-color-swatch',
  templateUrl: './color-swatch.component.html',
  styleUrls: ['./color-swatch.component.scss']
})
export class ColorSwatchComponent {
  @Input() color: SystemColor | CustomColor;
  @Input() isSystemColorPalette: boolean = false;
  show = false;

  @HostListener('focusin')
  @HostListener('mouseenter')
  onMouseEnter() {
    if (!this.isTouchScreen) {
      this.show = true;
    }
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  onMouseLeave() {
    this.show = false;
  }

  get isTouchScreen() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    // Helper function to check if a media query matches
    const mq = (query: any) => window.matchMedia(query).matches;

    // Quick check: if 'ontouchstart' exists, it's definitely a touch device
    if ('ontouchstart' in window) {
        return true;
    }

    // Fallback: construct a complex media query to test for touch capability
    // The 'heartz' part ensures the query doesn't end prematurely
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');

    // Return the result of our media query test
    return mq(query);
  }

  constructor(
    public translation: Translations
  ) {}
}
