import { Pipe, PipeTransform } from '@angular/core';
import { ColorType, CustomColor } from '@graphics-flow/types';

@Pipe({
  name: 'colorFormatter'
})
export class ColorFomatterPipe implements PipeTransform {

  // TODO: Need some changes, while working on CMYK picker in ColorPicker.
  transform(customColor: CustomColor, formatTo: string = ColorType.RGB, formatOption: string = 'short'): string {
    switch(formatOption) {
      case 'full':
        if (formatTo === ColorType.CMYK) {
          return `C ${customColor.cmyk.cyan}% M ${customColor.cmyk.magenta}% Y ${customColor.cmyk.yellow}% K ${customColor.cmyk.black}%`;
        } else if (formatTo === ColorType.HEX) {
          return `#${customColor.hexValue}`;
        }
        return `R ${customColor?.rgb.red} G ${customColor?.rgb.green} B ${customColor?.rgb.blue}`;
      case 'short':
        if (formatTo === ColorType.CMYK) {
          return `(${customColor.cmyk.cyan}%, ${customColor.cmyk.magenta}%, ${customColor.cmyk.yellow}%, ${customColor.cmyk.black}%)`;
        } else if (formatTo === ColorType.HEX) {
          return `${customColor.hexValue.slice(1)}`;
        }
        return `${customColor?.rgb.red} ${customColor?.rgb.green} ${customColor?.rgb.blue}`;
    }
  }

}
