<div class="color-picker" #dialogPopup>
  <div class="preset-area">
    <ng-container *ngFor="let group of colorGroups; last as isLastGroup">
      <ng-container *ngIf="(group.Colors)?.length > 0">
        <div class="preset-label" *ngIf="group.Label">{{group.Label}}</div>
        <div class="preset-container flex-container">
          <div *ngFor="let Color of group.Colors.slice(0, 6)" class="preset-color"
               [style.backgroundColor]="Color"
               [style.borderColor]="getBorderColor(Color)"
               (click)="setColorFromString(Color)"
               [hidden]="Color === 'none'"></div>
          <div class="flex-child-grow"></div>
          <div class="preset-color preset-color--transparent"
               (click)="clearColor()"
               *ngIf="isLastGroup && allowClear"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div [slider] [style.background-color]="hueSliderColor" [rgX]="1" [rgY]="1"
       (newValue)="setSaturationAndBrightness($event)" class="saturation-lightness" #saturationAndBrightnessSlider>
    <div [style.left.px]="slider.s" [style.top.px]="slider.v" class="cursor"></div>
  </div>
  <div class="box">
    <div class="left">
      <div class="selected-color-background"></div>
      <div [style.background-color]="color"
           [style.borderColor]="getBorderColor(color)"
           class="selected-color">
      </div>
    </div>
    <div class="right">
      <div [slider] [rgX]="1" (newValue)="setHue($event)" class="hue" #hueSlider>
        <div [style.left.px]="slider.h" class="cursor"></div>
      </div>
      <div *ngIf="showAlpha" [slider] [style.background-color]="alphaSliderColor" [rgX]="1"
           (newValue)="setAlpha($event)" class="alpha" #alphaSlider>
        <div [style.left.px]="slider.a" class="cursor"></div>
      </div>
    </div>
  </div>
  <div class="color-picker__inputs">
    <ng-container *ngIf="!showCMYKPicker; else cmykPicker">
      <div [hidden]="format!==2" class="hsla-text">
        <div class="box">
          <input [text] type="number" pattern="[0-9]*" min="0" max="360" [rg]="360" (newValue)="setHue($event)"
                 [value]="hslaText?.h"/>
          <input [text] type="number" pattern="[0-9]*" min="0" max="100" [rg]="100" (newValue)="setSaturation($event)"
                 [value]="hslaText?.s"/>
          <input [text] type="number" pattern="[0-9]*" min="0" max="100" [rg]="100" (newValue)="setLightness($event)"
                 [value]="hslaText?.l"/>
          <input *ngIf="showAlpha" [text] type="number" pattern="[0-9]+([\.,][0-9]{1,2})?" min="0" max="1" step="0.1"
                 [rg]="1" (newValue)="setAlpha($event)" [value]="hslaText?.a"/>
        </div>
        <div class="box">
          <div>H</div>
          <div>S</div>
          <div>L</div>
          <div *ngIf="showAlpha">A</div>
        </div>
      </div>
      <div [hidden]="format!==1" class="rgba-text">
        <div class="box">
          <input [text] type="number" pattern="[0-9]*" min="0" max="255" [rg]="255" (newValue)="setR($event)"
                 [value]="rgbaText?.r"/>
          <input [text] type="number" pattern="[0-9]*" min="0" max="255" [rg]="255" (newValue)="setG($event)"
                 [value]="rgbaText?.g"/>
          <input [text] type="number" pattern="[0-9]*" min="0" max="255" [rg]="255" (newValue)="setB($event)"
                 [value]="rgbaText?.b"/>
          <input *ngIf="showAlpha" [text] type="number" pattern="[0-9]+([\.,][0-9]{1,2})?" min="0" max="1" step="0.1"
                 [rg]="1" (newValue)="setAlpha($event)" [value]="rgbaText?.a"/>
        </div>
        <div class="box">
          <div>R</div>
          <div>G</div>
          <div>B</div>
          <div *ngIf="showAlpha">A</div>
        </div>
      </div>
      <div [hidden]="format!==0" class="hex-text">
        <div class="box">
          <input [text] (newValue)="setColorFromString($event)" [value]="hexText"/>
        </div>
        <div class="box">
          <div>Hex</div>
        </div>
      </div>
    </ng-container>
    <ng-template #cmykPicker>
      <div class="cmyk-text">
        <div class="box">
          <input [text] (newValue)="setColorFromString($event)" [value]="cmykText"/>
        </div>
        <div class="box">
          <div>CMYK</div>
        </div>
      </div>
    </ng-template>
    <div *ngIf="!showCMYKPicker" class="type-policy" (click)="formatPolicy()"></div>
  </div>
</div>
