import { Component, Input } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { CustomColor, SystemColor } from '@graphics-flow/types';

@Component({
  selector: 'gf-color-info-tooltip',
  templateUrl: './color-info-tooltip.component.html',
  styleUrls: ['./color-info-tooltip.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'color-info-tooltip'
  }
})
export class ColorInfoTooltipComponent {
  @Input() color: SystemColor | CustomColor;

  constructor(
    public translation: Translations
  ) {}
}
