<div id="colorInfoTooltip" class="flex-container align-middle">
  <div class="m-r-2 overflow-hidden color-name">
    <div class="t-12-400-h white-color">{{ translation.organization.tab.color_palettes_management.color_name | translate }}</div>
    <div id="colorName" class="t-12-600-h white-color text-truncate">{{ color.name }}</div>
  </div>
  <div class="m-r-2 overflow-hidden">
    <div class="t-12-400-h white-color">{{ translation.common.colors.hex | translate }}</div>
    <div id="hexValue" class="t-12-600-h white-color text-truncate">{{ color.hexValue }}</div>
  </div>
  <div class="overflow-hidden">
    <div class="t-12-400-h white-color">{{ translation.common.colors.rgb | translate }}</div>
    <div id="rgb" class="flex-container t-12-600-h white-color text-truncate">
      <span class="m-r-1">
        {{ color.rgb.red }}
      </span>
      <span class="m-r-1">
        {{ color.rgb.green }}
      </span>
      <span>
        {{ color.rgb.blue }}
      </span>
    </div>
  </div>
</div>
