import { Injectable } from '@angular/core';
import { setProps } from '@ngneat/elf';
import { Observable, tap } from 'rxjs';
import { CustomizeStockArtHttpService } from '@graphics-flow/api';
import { ColorPalette, ColorPaletteView, SystemColor } from '@graphics-flow/types';
import { ColorPaletteStore } from './color-palette.store';

@Injectable({
  providedIn: 'root'
})
export class ColorPaletteService {
  constructor(
    private readonly colorPaletteStore: ColorPaletteStore,
    private readonly customizeStockArtHttpService: CustomizeStockArtHttpService
  ) {}

  updateManageColorPalette<T>(key: string, value: T): void {
    this.colorPaletteStore.update(setProps({ [key]: value }));
  }

  getSystemColorPalette(): Observable<SystemColor[]>  {
    return this.customizeStockArtHttpService.getColors().pipe(
      tap((data: SystemColor[]) => this.updateManageColorPalette<SystemColor[]>('systemColors', data))
    );
  }

  getColorPalettes(): Observable<ColorPalette[]> {
    return this.customizeStockArtHttpService.getColorPalettes().pipe(
      tap((data: ColorPalette[]) => this.updateManageColorPalette<ColorPalette[]>('colorPalettes', data))
    );
  }

  setColorPaletteActiveView(viewType: ColorPaletteView): void {
    this.updateManageColorPalette<ColorPaletteView>('colorPaletteActiveView', viewType);
  }

  setActiveColorPalette(colorPalette?: ColorPalette): void {
    this.updateManageColorPalette<ColorPalette>('activeColorPalette', colorPalette);
  }

  createNewColorPalette(colorPalette: ColorPalette): Observable<ColorPalette> {
    return this.customizeStockArtHttpService.createColorPalette(colorPalette).pipe(
      tap((newColorPalette) => {
        this.colorPaletteStore.update((state)=> ({
          ...state,
          colorPalettes: [...state.colorPalettes, newColorPalette]
        }));
      })
    );
  }
}
